//import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="full-page">
      <Title />
      
      <DrinkSection name="Classic"/>

      <DrinkSubSection name="Traditional Classics"/>

      <Drink 
        name="Daiquiri"
        ingredients="blended lightly aged rum, lime juice, cane syrup"
      />

      <Drink 
        name="Manhattan"
        ingredients="rye, sweet vermouth, angostura bitters, maraschino cherry"
      />

      <Drink 
        name="Martini"
        ingredients="gin, dry vermouth"
      />

      <Drink 
        name="Sidecar"
        ingredients="cognac, curaçao, lemon juice, simple syrup"
      />

      <Drink 
        name="Margarita"
        ingredients="blanco tequila, cointreau, lime juice"
      />  

      <Drink 
        name="Boulevardier"
        ingredients="bourbon, sweet vermouth, campari"
      />

      <Drink 
        name="Pisco Sour"
        ingredients="peruvian pisco, lime juice, simple syrup, angostura bitters"
      />

      <Drink 
        name="Bee's Knees"
        ingredients="gin, honey syrup, lemon juice, lavender bitters"
      />

      <DrinkSubSection name="Modern Classics"/>  

      <Drink 
        name="Jack Rose"
        ingredients="apple brandy, grenadine, lime juice"
      />

      <Drink 
        name="Paper Plane"
        ingredients="bourbon, amaro nonino, aperol, lemon juice"
      />
      
      <Drink 
        name="Last Word"
        ingredients="gin, green chartreuse, maraschino liqueur, lime juice"
      />

      <Drink 
        name="Naked and Famous"
        ingredients="mezcal, yellow chartreuse, aperol, lime juice"
      />

      <Drink 
        name="Boukman Daiquiri"
        ingredients="column still aged rum, cognac, cinnamon syrup, lime juice"
      />

      <Drink 
        name="Smokescreen"
        ingredients="famous grouse scotch, laphroaig, simple syrup, lime juice, green chartreuse, mint"
      />

      <DrinkSection name="Tiki"/>

      <DrinkSubSection name="Adventure Awaits"/>

      <Drink 
        name="Saturn"
        ingredients="london dry gin, lemon juice, passion fruit syrup, orgeat, velvet falernum"
      />

      <Drink 
        name="Hurricane"
        ingredients="black blended rum, lemon juice, passion fruit syrup"
      />

      <Drink 
        name="Jungle Bird"
        ingredients="black blended rum, pineapple juice, campari, lime juice, demerara syrup"
      />

      <Drink 
        name="Chartreuse Swizzle"
        ingredients="green chartreuse, velvet falernum, pineapple juice, lime juice"
      />

      <Drink 
        name="Norwegian Paralysis"
        ingredients="aquavit, orange juice, pineapple juice, lemon juice, demerara syrup, orgeat"
      />

      <DrinkSubSection name="Let the sprits guide you"/>

      <Drink 
        name="Grog"
        ingredients="rum, lime juice, demerara syrup (can be ordered in 1600's, 1800's, or modern day style)"
      />
      
      <Drink 
        name="Barbados Rum Punch"
        ingredients="barbados aged rum, lime juice, demerara syrup, angostura bitters"
      />

      <Drink 
        name="Mai Tai"
        ingredients="blended aged rum, lime juice, curaçao, orgeat, demerara simple syrup"
      />

      <Drink 
        name="Doctor Funk"
        ingredients="black pot still rum, lemon juice, lime juice, demerara simple syrup, absinthe, grenadine, seltzer"
      />

      <Drink 
        name="Zombie"
        ingredients="column still aged rum, blended aged rum, black blended rum, lime juice, velvet falernum, grapefruit juice, cinnamon syrup, grenadine, herbstura"
      />

      <Drink 
        name="Puka Punch"
        ingredients="blended aged rum, blended lightly aged rum, black blended rum, honey syrup, lime juice, passion fruit syrup, orange juice, pineapple juice, velvet falernum, angostura, black blended overproof rum float"
      />

    </div>
  );
}

function Title() {
  return(
      <div className="page-title">
        Rosiewood
      </div>
  );
}

function DrinkSection(props) {
  return(
    <div className="section-title">
      {props.name}
    </div>
  );
}

function DrinkSubSection(props) {
  return(
    <div className="subsection-title">
      {props.name}
    </div>
  );
}

function Drink(props) {
  return(
    <div className="drink">
      <div className="drink-title">{props.name}</div>
      <DrinkIngredients list={props.ingredients}/>
    </div>
  );
}

function DrinkIngredients(props) {
  return(
    <div className="ingredients">
      {props.list}
    </div>
  );
}



export default App;
